<template>
  <div class="projects-info-list item-list item-report-summary">
    <table class="no-row-border">
      <thead>
        <tr>
          <th>Project</th>
          <th>Company</th>
          <th>License</th>
          <th>V.</th>
          <th>V. Req.</th>
          <th>Billing Medium</th>
          <th>Trail</th>
          <th>Status</th>
          <th>Created</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="project in projects"
          :key="project.key"
          @click="showProjectsInfo(project.key)"
        >
          <td class="project-name">
            <router-link
            :to="{ name: 'ProjectPage', params: { projectKey: project.key }}"
            >{{project.name}}</router-link>
          </td>
          <td>
            <div v-if="project.billing">
              <a :href="'mailto:' + project.billing.contact_email">
                {{project.billing.contact_name || '-'}}
                <span
                  v-if="project.billing.company_name"
                > / {{project.billing.company_name}}</span>
              </a>
            </div>
            <div v-else>-</div>
          </td>
          <td  @click="showProjectsInfo(project.key)">
            <span>{{project.project_license.name}}</span>
          </td>
          <td @click="showProjectsInfo(project.key)">{{project.isBillingVerified ? '✅': 'No'}}</td>
          <td @click="showProjectsInfo(project.key)">{{project.requireBillingVerification ? '✅': 'No'}}</td>
          <td @click="showProjectsInfo(project.key)">{{project.billingMedium}}</td>
          <td @click="showProjectsInfo(project.key)">{{project.group === 'customer_trail_project' ? 'Yes' : 'No'}}</td>
          <td @click="showProjectsInfo(project.key)">{{project.status ? project.status.value : '-'}}</td>
          <td @click="showProjectsInfo(project.key)">{{ $filters.utcLocalDate(project.created) }}</td>
        </tr>
      </tbody>
    </table>
    <div class="popup-wrapper"  @click="showPopup = !showPopup" v-if="showPopup">
    </div>
    <div class="project-info-popup" v-if="showPopup">
      <div class="info-div">
        <div class="project-info-wrap">
          <h3 class="project-info">Project Info</h3>
        </div>
        <div class="close-btn-wrap">
          <button @click="showPopup = !showPopup; isDeleteModalOpen = false" class="close-btn">X</button>
        </div>
        <div class="details-wrapper">
          <div class="details">
            <div class="project-details-wrap card">
              <svg class="title-icon">
                <use v-bind="{ 'xlink:href': '#key-icon' }" />
              </svg>
              <h4>Project Details</h4>
              <p>
                <span class="project-name main-info">{{projectInfo.name}}</span>
              </p>
              <p>
                <span>{{projectInfo.key}}</span>
              </p>
              <p>
                <span>{{projectInfo.project_license[0].name}}</span>
              </p>
              <p>
                <span>{{projectInfo.group}}</span>
              </p>
              <div class="console-links">
                <router-link
                  :to="{ name: routeListObj[0].routeName, params: { projectKey: projectInfo.key } }"
                  class="links"
                  :class="{'active': $route.name === routeListObj[0].routeName}"
                >
                  <svg class="link-icon">
                    <use v-bind="{ 'xlink:href': routeListObj[0].svgIcon }" />
                  </svg>
                  <span class="link-text">{{routeListObj[0].displayName}}</span>
                </router-link>
                <button class="links primary" @click="isProjectGroupModalOpen = !isProjectGroupModalOpen">
                  Change Project Group
                </button>
                <button class="links error" @click="isDeleteModalOpen = !isDeleteModalOpen">
                  Delete project
                </button>
            </div>
            </div>
            <div class="user-details-wrap card">
              <svg class="title-icon">
                <use v-bind="{ 'xlink:href': '#user-icon' }" />
              </svg>
              <h4>User Details</h4>
              <p>
                <span class="project-name main-info">{{projectInfo.created_by.name}}</span>
              </p>
              <p>
                <span class="project-name">
                  <a :href="'mailto:'+ projectInfo.created_by.mail">
                  {{projectInfo.created_by.email}}</a>
                </span>
              </p>
              <p v-if="projectInfo.created_by.properties.company_name">
                <span>{{projectInfo.created_by.properties.company_name}}</span>
              </p>
              <p>
                <span>{{projectInfo.created_by.email_verified ? 'Email is Verified': 'Email not verified'}}</span>
              </p>
            </div>
            <div class="billing-details-wrap card" v-if="projectInfo.billing">
              <svg class="title-icon">
                <use v-bind="{ 'xlink:href': '#billing-icon' }" />
              </svg>
              <h4>Billing Details</h4>
              <div>
                <p>
                  <span class="project-name main-info">{{projectInfo.billing.contact_name}}</span>
                </p>
                <p>
                  <span class="project-name">{{projectInfo.billing_method}}</span>
                </p>
                <p>
                  <span><a :href="'mailto:'+ projectInfo.billing.contact_email">
                    {{projectInfo.billing.contact_email}}</a></span>
                </p>
                <p v-if="projectInfo.custom_billing_limit">
                  <span>{{projectInfo.custom_billing_limit}}</span>
                </p>
                <p>
                  <span>{{ projectInfo.billing.address }}
                  <br />
                  {{ projectInfo.billing.city }}, {{ projectInfo.billing.state }}
                  <br />
                  {{ projectInfo.billing.country }}, {{ projectInfo.billing.zip_code }}
                  <br />
                  </span>
                </p>
                <p>
                  <span v-if="projectInfo.billing.gst_number">{{projectInfo.billing.gst_number}},</span>
                </p>
                <p>
                  <span v-if="projectInfo.billing.preferred_payment_medium">
                    {{projectInfo.billing.preferred_payment_medium}},</span>
                  <span v-if="projectInfo.billing.country">&nbsp; {{projectInfo.billing.country}}</span>,
                  <span v-if="projectInfo.billing.billing_currency" class="currency">
                    &nbsp; {{projectInfo.billing.billing_currency}}</span>
                </p>
              </div>
              <div class="console-links actions">
                <router-link
                  :to="{ name: routeListObj[2].routeName, params: { projectKey: projectInfo.key } }"
                  class="links"
                  :class="{'active': $route.name === routeListObj[2].routeName}"
                >
                  <svg class="link-icon">
                    <use v-bind="{ 'xlink:href': routeListObj[2].svgIcon }" />
                  </svg>
                  <span class="link-text">{{routeListObj[2].displayName}}</span>
                </router-link>
                <button @click="unverifyBilling(projectInfo.key)" class="unverify-btn"
                  v-if="projectInfo.billing.is_verified">
                  Unverify Billing
                </button>
                <button @click="verifyBilling(projectInfo.key)" class="verify-btn"
                v-else>
                  Verify Billing
                </button>
                <button @click="isChangeBillModalOpen = !isChangeBillModalOpen">
                  Change Billing Method
                </button>
                <button @click="isEditLimitModalOpen = !isEditLimitModalOpen">
                  Edit Project Limit
                </button>
                <p class="message"> {{ message }} </p>
              </div>
            </div>
          </div>
          <div class="free-trail-detail-wrap card">
            <h4>Free Trail Details</h4>
            <p v-if="projectInfo.free_trail_detail">Trail period days  <span>
              {{projectInfo.free_trail_detail.trail_period_days}}</span></p>
            <div class="actions">
              <button @click="isFreeTrailModalOpen = !isFreeTrailModalOpen"
               :class="projectInfo.group !== 'customer_trail_project' ? 'activate-btn' : 'deactivate-btn'">
                {{ projectInfo.group !== 'customer_trail_project' ? 'Activate' : 'Deactivate' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="delete-project-modal" :class="isDeleteModalOpen ? 'show' : null"  v-if="projectInfo">
        <div class="delete-project-title">
          <h3>Delete Project</h3>
          <button @click="isDeleteModalOpen = !isDeleteModalOpen" class="close-btn">X</button>
        </div>
        <div class="delete-modal-body">
          <p class="align-center"> Are you sure, you want to delete the project ? </p>
          <div class="console-links btn-group align-center">
            <button class="links success" @click="isDeleteModalOpen = !isDeleteModalOpen">
              close
            </button>
            <button class="links error" @click="deleteProject(projectInfo.key, 'test_project')">
              Confirm
            </button>
          </div>
          <p class="align-center">{{message}}</p>
        </div>
      </div>
      <div class="delete-project-modal" :class="isProjectGroupModalOpen ? 'show' : null"  v-if="projectInfo">
        <div class="delete-project-title">
          <h3>Change Project Group</h3>
          <button @click="isProjectGroupModalOpen = !isProjectGroupModalOpen" class="close-btn">X</button>
        </div>
        <div class="delete-modal-body">
          <p>
            <span class="title">Project Group</span>
            <select v-model="changeProjectGroupKey">
              <template v-for="project in projectCategory" :key="project.key">
                <option :value="project.project_key">{{project.project_name}}</option>
              </template>
            </select>
          </p>
          <div class="console-links btn-group">
            <button class="links success" @click="isProjectGroupModalOpen = !isProjectGroupModalOpen">
              close
            </button>
            <button class="links primary" @click="changeProjectGroup(projectInfo.key, changeProjectGroupKey)">
              Confirm
            </button>
          </div>
          <p class="message">{{message}}</p>
        </div>
      </div>
      <div class="delete-project-modal" :class="isFreeTrailModalOpen ? 'show' : null" v-if="projectInfo">
        <div class="delete-project-title">
          <h3>Activate Free trail</h3>
          <button @click="isFreeTrailModalOpen = !isFreeTrailModalOpen" class="close-btn">X</button>
        </div>
        <div class="delete-modal-body">
          <p>
            <span class="title">Free trail days</span>
            <input type="number" v-model.number="freeTrailDays">
          </p>
          <div class="console-links btn-group actions">
            <button class="links success" @click="isFreeTrailModalOpen = !isFreeTrailModalOpen">
              close
            </button>
            <button @click="activateFreeTrail(projectInfo.key)" class="activate-btn"
              v-if="projectInfo.group !== 'customer_trail_project'">Activate</button>
            <button @click="deactivateFreeTrail(projectInfo.key)" class="links error" v-else>Deactivate</button>
          </div>
          <p class="message">{{message}}</p>
        </div>
      </div>
      <div class="change-billing-modal" :class="isChangeBillModalOpen ? 'show' : null"  v-if="projectInfo">
        <div class="delete-project-title">
          <h3>Change Billing Method</h3>
          <button @click="isChangeBillModalOpen = !isChangeBillModalOpen" class="close-btn">X</button>
        </div>
        <div class="delete-modal-body">
          <p>
            <span class="title">Billing Method</span>
            <select v-model="chosenBillingMethod">
              <template v-for="method in billingMethod" :key="method.key">
                <option :value="method">{{method.name}}</option>
              </template>
            </select>
          </p>
          <div class="console-links btn-group">
            <button class="links success" @click="isChangeBillModalOpen = !isChangeBillModalOpen">
              close
            </button>
            <button class="links primary" @click="changeBillingMethod(projectInfo.key, chosenBillingMethod)">
              Confirm
            </button>
          </div>
          <p class="message">{{message}}</p>
        </div>
      </div>
      <div class="edit-limit-modal" :class="isEditLimitModalOpen ? 'show' : null"  v-if="projectInfo">
        <div class="delete-project-title">
          <h3>Edit Project Limit</h3>
          <button @click="isEditLimitModalOpen = !isEditLimitModalOpen" class="close-btn">X</button>
        </div>
        <div class="delete-modal-body">
          <p>
            <span class="title">Set Limit</span>
            <input type="number" v-model="projectLimit">
          </p>
          <div class="console-links btn-group">
            <button class="links success" @click="isEditLimitModalOpen = !isEditLimitModalOpen">
              close
            </button>
            <button class="links primary" @click="editProjectLimit(projectInfo.key, projectLimit)">
              Confirm
            </button>
          </div>
          <p class="message">{{message}}</p>
        </div>
      </div>
  </div>
</template>
<script>
import { Enum } from '@/core/utils/enum';
import { ProjectList } from '@/store/modules/admin';
import { Project } from '@/store/modules/project';

const routeNameList = new Enum({
  dashboard: 'ProjectPage',
  usageReport: 'usageReport',
  billing: 'billing',
  settings: 'settings',
  pushConfiguration: 'pushConfiguration',
  transactions: 'transactions',
});
export default {
  props: {
    projects: Object,
  },
  data() {
    return {
      showPopup: false,
      projectInfo: null,
      freeTrailDays: null,
      limit: null,
      message: null,
      projectCategory: [
        {
          project_name: 'Roanuz developers project',
          project_key: 'roanuz_developers_project',
        },
        {
          project_name: 'Roanuz internal service project',
          project_key: 'roanuz_internal_service_project',
        },
        {
          project_name: 'Customer trail project',
          project_key: 'customer_trail_project',
        },
        {
          project_name: 'Live project',
          project_key: 'live_project',
        },
        {
          project_name: 'Test project',
          project_key: 'test_project',
        },
      ],
      chosenBillingMethod: null,
      billingMethod: [
        {
          name: 'Pay By Invoice',
          key: 'pay_by_invoice',
        },
        {
          name: 'Prepay',
          key: 'prepay',
        },
        {
          name: 'Unknown',
          key: 'unknown',
        },
      ],
      changeProjectGroupKey: 'customer_trail_project',
      routeListObj: [
        {
          routeName: routeNameList.dashboard.value,
          displayName: 'Dashboard',
          svgIcon: '#dashboard-icon',
        },
        {
          routeName: routeNameList.usageReport.value,
          displayName: 'Usage Report',
          svgIcon: '#usage-icon',
        },
        {
          routeName: routeNameList.billing.value,
          displayName: 'Billing',
          svgIcon: '#billing-icon',
        },
        {
          routeName: routeNameList.transactions.value,
          displayName: 'Transactions',
          svgIcon: '#transaction-icon',
        },
        {
          routeName: routeNameList.pushConfiguration.value,
          displayName: 'Push Configuration',
          svgIcon: '#configuration-icon',
        },
        {
          routeName: routeNameList.settings.value,
          displayName: 'Settings',
          svgIcon: '#settings-icon',
        },
      ],
      routeNameList,
      isDeleteModalOpen: false,
      isProjectGroupModalOpen: false,
      isFreeTrailModalOpen: false,
      isChangeBillModalOpen: false,
      isEditLimitModalOpen: false,
    };
  },
  methods: {
    showProjectsInfo(key) {
      ProjectList.fetchProjectInfo(key).then((resp) => {
        this.showPopup = true;
        this.projectInfo = resp;
      }).catch((err) => {
        console.log('show projects info', err);
      });
    },
    activateFreeTrail(key) {
      if (this.freeTrailDays) {
        return ProjectList.activateFreeTrail(key, this.freeTrailDays).then((resp) => {
          if (resp) {
            this.showMessage('Trail Activated');
          }
        }).catch(() => {
          this.showMessage();
        });
      }
      return true;
    },
    deactivateFreeTrail(key) {
      return ProjectList.deactivateFreeTrail(key).then((resp) => {
        if (resp) {
          this.showMessage('Trail Deactivated');
        }
      }).catch(() => {
        this.showMessage();
      });
    },
    verifyBilling(key) {
      return ProjectList.verifyBilling(key).then((resp) => {
        if (resp) {
          this.showMessage('Billing Verified');
        }
      }).catch(() => {
        this.showMessage();
      });
    },
    unverifyBilling(key) {
      return ProjectList.unverifyBilling(key).then((resp) => {
        if (resp) {
          this.showMessage('Billing unverified');
        }
      }).catch(() => {
        this.showMessage();
      });
    },

    changeProjectGroup(key, group) {
      return ProjectList.changeProjectGroups(key, group).then((resp) => {
        if (resp) {
          this.showMessage('Project Group has been changed');
        }
      }).catch(() => {
        this.showMessage();
      });
    },

    changeBillingMethod(key, method) {
      return ProjectList.changeBillingMethod(key, method.key).then((resp) => {
        if (resp) {
          this.showMessage(`Billing Method has been changed to ${method.name}`);
        }
      });
    },

    editProjectLimit(key, limit) {
      limit = parseFloat(limit);
      return ProjectList.editProjectLimit(key, limit).then((resp) => {
        if (resp) {
          this.showMessage(`Project Limit is set to ${limit}`);
        }
      });
    },

    deleteProject(key, group) {
      return ProjectList.changeProjectGroups(key, group).then((resp) => {
        if (resp) {
          Project.deleteProject(key).then((deleteResp) => {
            console.log('delete poject ', deleteResp);
            this.showMessage('Project has been deleted');
          });
        }
      }).catch(() => {
        this.showMessage();
      });
    },

    showMessage(msg) {
      if (msg) {
        this.message = msg;
        setTimeout(() => {
          this.message = '';
        }, 4000);
      } else {
        this.message = 'Error occured';
        setTimeout(() => {
          this.message = '';
        }, 4000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.projects-info-list {
  --projects--header--Color: #e1eaff;
  --projects--wrapper-Color: rgba(0, 0, 0, 0.4);

  .projects-list-table {
    tbody {
      tr {
        cursor: pointer;
        .project-name {
          overflow: hidden;
          max-width: size(120);
          text-overflow: ellipsis;
        }
      }
    }
  }
  .popup-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--projects--wrapper-Color);
    z-index: 99;
  }
  .project-info-popup {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    width: size(700);
    border-radius: size(6);
    box-shadow: size(0) size(2) size(4) rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
    .info-div {
      h3 {
        @include font-styles($type: 'display', $size: 'sm', $weight: 'semibold');
        background: var(--projects--header--Color);
        padding: size(20);
        text-align: center;
        &.project-info {
          background: var(--global--primary--BackgroundColor);
          border-radius: size(6);
        }
      }
      .project-info-wrap {
        padding: size(20) size(20) size(5) size(20);
      }
      .close-btn {
        transform: translate(#{size(-20), #{size(20)}});
        &-wrap {
          background: var(--global--primary--BackgroundColor);
        }
      }
      .details-wrapper {
        padding: size(15);
        h4 {
          @include font-styles($type: 'text', $size: 'lg', $weight: 'bold');
          margin: size(15) 0 size(20);
          display: inline-block;
          padding-left: size(5);
        }
        p {
          padding-bottom: size(10);
          span {
            display: inline-block;
            vertical-align: top;
            @include font-styles($type: 'text', $size: 'md', $weight: 'normal');
            &.title {
              @include font-styles($type: 'text', $size: 'md', $weight: 'semibold');
              color: var(--global--heading--Color);
            }
            &.project-name {
              overflow: hidden;
              max-width: size(300);
              text-overflow: ellipsis;
              &.main-info {
                @include font-styles($type: 'text', $size: 'lg', $weight: 'semibold');
              }
            }
            &.committed-use {
              span {
                display: block;
              }
            }
          }
        }
        .actions {
          margin: size(20) 0 size(0);
          p{
            padding: 0 0 size(30);
            font-weight: bold;
            &.message {
              display: inline-block;
              padding-top: size(10);
              text-align: center;
            }
          }
          button {
            outline: none;
            border: none;
            padding: size(8);
            cursor: pointer;
            border-radius: size(4);
            color: var(--global--white--Color);
            font-weight: bold;
            margin-right: size(10);
            margin-bottom: size(15);
            background: var(--global--link--Color);
            &.activate-btn {
              background: var(--global--success--BackgroundColor);
              cursor: pointer;
            }
            &.deactivate-btn {
              background: var(--global--error--BackgroundColor);
              cursor: pointer;
            }
            &:hover {
              animation: jump-up-shadow 350ms ease-out;
            }
          }
        }
        .margin-top-20 {
          margin-top: size(20);
        }
        .free-trail-detail-wrap {
          h4 {
            margin-bottom: 0;
          }
          p {
            padding-top: size(10);
            span {
              padding-left: size(10);
            }
          }
        }
      }
    }
  }
  .delete-project-modal, .change-billing-modal, .edit-limit-modal {
    position: fixed;
    z-index: 101;
    top: 0;
    right: 0;
    bottom: 0;
    width: 43.75rem;
    border-radius: 0.375rem;
    background: var(--global--white--Color);
    width: size(700);
    border-radius: size(6);
    box-shadow: size(0) size(2) size(4) rgba(0, 0, 0, 0.1);
    display: none;
    h3 {
      @include font-styles($type: 'display', $size: 'sm', $weight: 'semibold');
      background: var(--projects--header--Color);
      padding: size(20);
      margin-bottom: size(50);
      text-align: center;
    }
    &.show {
      display: block;
    }
    p, .console-links {
      margin-top: size(10);
      text-align: center;
      z-index: 102;
      opacity: 1;
    }
    select {
      // margin-left: size(10);
    }
    .delete-modal-body,.bill-modal-body {
      max-width: size(500);
      margin: auto;
      p {
        text-align: left;
        &.align-center {
          text-align: center;
        }
      }
      span {
        width: size(250);
        display: inline-block;
        &.title {
          text-align: left;
        }
      }
      input {
        width: size(250);
      }
    }
  }
  .console-links {
    // margin-bottom: size(20);
    .link-icon {
      display: inline-block;
      vertical-align: top;
      margin-right: size(6);
      height: size(14);
      width: size(14);
      fill: var(--global--main--Color);
    }
    .link-text {
      display: inline-block;
      vertical-align: top;
    }
    a, button{
      display: inline-block;
      vertical-align: top;
      padding: size(8);
      outline: none;
      border: none;
      cursor: pointer;
      border-radius: size(4);
      background: var(--global--supportive--Color);
      color: var(--global--main--Color);
      margin: 0 size(10) size(10) 0;
      @include font-styles($type: 'text', $size: 'md', $weight: 'normal');
      transition: all 0.3s linear;
      &:hover {
        background: var(--global--link--Color);
        color: var(--global--white--Color);
        animation: jump-up-shadow 350ms ease-out;
        svg {
          fill: var(--global--white--Color);
        }
      }
      &.size-md {
        width: size(90);
      }
    }
    &.btn-group {
      text-align: right;
      margin-top: size(20);
      &.align-center {
        text-align: center;
      }
    }
    .links {
      &:hover {
        &.success {
          background-color: var(--global--success--BackgroundColor);
        }
      }
      &.error {
        background-color: var(--global--error--BackgroundColor);
        color: var(--global--white--Color);
      }
      &.primary {
        background-color: var(--global--link--Color);
        color: var(--global--white--Color);
      }
    }
    .toggle {
      @include font-styles($type: 'text', $size: 'md', $weight: 'semibold');
      color: var(--global--link--Color);
      background: none;
      text-decoration: underline;
      margin: 0;
      padding-left: 0;
      display: block;
      &:hover {
        background: none;
        color: var(--global--link--Color);
      }
    }
  }
  .close-btn {
    position: absolute;
    top: size(25);
    right: size(20);
    font-weight: bold;
    outline: none;
    border: none;
    cursor: pointer;
  }
  svg.title-icon {
    height: size(14);
    width: size(14);
  }
  .card {
    background: var(--global--primary--BackgroundColor);
    margin: size(20) 0;
    border-radius: size(6);
    padding: size(15);
    &:first-child {
      margin-top: size(0);
    }
  }
  .message {
    display: inline-block;
    padding-top: size(10);
    text-align: center;
  }
}
</style>
