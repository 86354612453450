<template>
  <div class="screen-admin-page screen-admin-projects">

    <rz-request-state :state="state" />
    <div
      v-if="state.status.isLoaded"
    >
      <div class="toolbar">
        <div class="title">
          <h1>Projects ({{filteredProjects.length}}/{{projects.length}})</h1>
        </div>
      </div>
      <div class="admin-screen-content">
        <div class="filterables">
          <div class="filter-container">
            <ul class="filter-items">
              <li>
                <label>
                  <input type="checkbox" v-model="showActiveProjects" />
                  Show Active Projects
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" v-model="showProjectsWithBilling" />
                  Show Projects with Billing
                </label>
              </li>
              <li>
                <label>
                  <input type="checkbox" v-model="showRzProjects" />
                  Show Roanuz Projects
                </label>
              </li>
            </ul>
          </div>
          <div class="filtered-content">
            <ProjectListComp :projects="rzProjects" v-if="showRzProjects"/>
            <ProjectListComp :projects="filteredProjects" v-else/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted } from 'vue';
import { RequestState, RequestStateStatus } from '@/api/core';
import { ProjectList } from '@/store/modules/admin';
import ProjectListComp from '@/components/admin/ProjectList.vue';

export default {
  components: {
    ProjectListComp,
  },

  setup() {
    const state = ref(new RequestState());
    const projects = ref([]);
    const rzProjects = ref([]);
    const showRzProjects = ref(false);
    const showActiveProjects = ref(true);
    const showProjectsWithBilling = ref(true);

    const fetchData = () => {
      state.value.status = RequestStateStatus.Loading;
      ProjectList.fetch().then((projectList) => {
        projectList.sort((x, y) => y.created - x.created);
        rzProjects.value = [];
        projects.value = [];
        projectList.forEach((proj) => {
          if (proj.billing && proj.billing.contact_email && proj.billing.contact_email.endsWith('roanuz.com')) {
            rzProjects.value.push(proj);
          } else {
            projects.value.push(proj);
          }
        });
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };

    const toggleRZProjects = () => {
      showRzProjects.value = !showRzProjects.value;
    };

    const filteredProjects = computed(() => projects.value.filter((proj) => {
      if (showActiveProjects.value && (!proj.status || !proj.status.isActive)) {
        return false;
      }
      if (showProjectsWithBilling.value && (!proj.billing || !proj.billing.contact_email)) {
        return false;
      }
      return true;
    }));

    onMounted(fetchData);

    return {
      state,
      projects,
      rzProjects,
      showRzProjects,
      toggleRZProjects,
      filteredProjects,
      showActiveProjects,
      showProjectsWithBilling,
    };
  },
};
</script>
<style lang="scss">
  .screen-admin-projects {
    .rz-projects {
      margin: size(30) 0;
    }
  }
</style>
